import React from 'react';
import { graphql } from 'gatsby';
import {
  DontDo,
  IconItemList,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import pageHeroData from '../../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Logos"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Icons" tierThree="Logos" />
      <V5Notice
        componentName="Icon"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-icon--docs"
      />
      <PageNavigation links={['Icons', 'Usage', 'Platform']} />
      <Section title="Icons">
        <IconItemList data={data} />
      </Section>
      <Section title="Usage">
        <Paragraph>
          Product and service logos are as direct and timeless as the Hudl logo
          itself. Treat them well.
        </Paragraph>
        <DontDo
          dontText="change the colors outside of approved brand variations."
          doText="use brand orange to maintain the integrity of the Hudl brand and its products."
          imgFilename="icons-logos"
        />

        <DontDo
          dontText="use product logos to represent an empty state for failed data."
          doText="make their purpose clear when representing Hudl or its products."
          imgFilename="icons-logos-emptystate"
        />
      </Section>
      <Section title="Platform">
        <PlatformTable
          platforms={{
            web: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            apple: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
            android: (
              <List>
                <li>Stick to the icons above.</li>
              </List>
            ),
          }}
        />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query iconLogosDesign($title: String = "Logos") {
    ...iconDataDesign
  }
`;
